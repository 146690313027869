//jscs:disable requireCamelCaseOrUpperCaseIdentifiers

var Timflow_widget = function (options) {
  var vars = {
    mapContainer: 'widget_parent',
    relativeTime: 20000,
    isRunning: false,
    game_time: 0,
  };

  var root = this;
  root.isRunning = vars.isRunning;
  root.game_time = vars.game_time;
  root.timings = [];
  root.markers = {};
  root.lines = {};

  this.construct = function (options) {
    $.extend(vars, options);
  };

  this.init = function () {
    initMap();
  };

  var mapStyles = [
    {
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'landscape',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ];

  $('#widget_playBtn').click(function () {
    timflow_widget.toggle_play();
  });

  var initMap = function () {
    // var center = {lat: 46.123869, lng:24.869157};
    // var center = {lat:46.770318, lng:24.793844}
    var center =
      window.innerWidth < 750
        ? { lat: 45.053869, lng: 24.759157 }
        : { lat: 46.023869, lng: 24.759157 };
    var zoom = window.innerWidth < 750 ? 6 : 7;

    var map = new google.maps.Map(document.getElementById(vars.mapContainer), {
      zoom: zoom,
      center: center,
      disableDefaultUI: true,
      gestureHandling: 'none',
      zoomControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      backgroundColor: '#FFF',
      styles: mapStyles,
    });
    root.map = map;
    // var myoverlay = new google.maps.OverlayView();
    // myoverlay.draw = function () {
    //   this.getPanes().markerLayer.id = 'markerLayer';
    // };

    // myoverlay.setMap(map);

    var factoriesPositions = {
      radauti: {
        position: { lat: 47.855052, lng: 25.967631 },
        icon: {
          url: 'images/marker_radauti.png',
          scaledSize: new google.maps.Size(80, 80),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(42, 59),
        },
      },
      sebes: {
        position: { lat: 45.967591, lng: 23.54725 },
        icon: {
          url: 'images/marker_sebes.png',
          scaledSize: new google.maps.Size(80, 80),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(42, 59),
        },
      },
      reci: {
        position: { lat: 45.852792, lng: 25.944332 },
        icon: {
          url: 'images/marker_reci.png',
          scaledSize: new google.maps.Size(80, 80),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(42, 59),
        },
      },
    };

    root.transportIcon = {
      url: 'images/marker_transport.png',
      size: new google.maps.Size(30, 30),
      scaledSize: new google.maps.Size(20, 20),
      origin: new google.maps.Point(0, 0),
      // anchor: new google.maps.Point(15, 21)
      anchor: new google.maps.Point(10, 10),
    };

    root.factoriesPositions = factoriesPositions;

    var markerRadauti = new google.maps.Marker({
      position: factoriesPositions.radauti.position,
      map: map,
      icon: factoriesPositions.radauti.icon,
    });

    var markerSebes = new google.maps.Marker({
      position: factoriesPositions.sebes.position,
      map: map,
      icon: factoriesPositions.sebes.icon,
    });

    var markerReci = new google.maps.Marker({
      position: factoriesPositions.reci.position,
      map: map,
      icon: factoriesPositions.reci.icon,
    });

    map.data.loadGeoJson('js/country.geojson');
    map.data.setStyle({
      fillColor: '#e3efc6',
      strokeWeight: 1,
      strokeColor: '#c8c9cb',
      fillOpacity: 0.6,
    });

    read_data();
  };

  var read_data_old = function () {
    Papa.parse('assets/js/widget/data.csv', {
      download: true,
      header: true,
      dynamicTyping: true,
      complete: function (results) {
        var filteredResults = results.data.filter(function (log) {
          return (
            log.status == 'accepted' &&
            log.start_position_lat != '' &&
            log.start_position_lng != '' &&
            (log.location == 'Reci' ||
              log.location == 'Radauti' ||
              log.location == 'Sebes')
          );
        });

        root.logEntries = filteredResults;
        root.startDate = moment(filteredResults[0].start_at).valueOf();

        generate_timedisplay();
        generate_timings();
      },
    });
  };

  var read_data = function () {
    var url = 'api/transports';
    var data = {
      api_token: 'ckR4Kx5VanPJW6CNYho0EXHbqf2eOMLB',
      start: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD HH:mm:ss'),
      end: moment().format('YYYY-MM-DD HH:mm:ss'),
    };

    var headers = {
      Authorization: 'Bearer ckR4Kx5VanPJW6CNYho0EXHbqf2eOMLB',

      'X-start': moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD HH:mm:ss'),
      'X-end': moment().format('YYYY-MM-DD HH:mm:ss'),
    };

    $.ajax({
      type: 'POST',
      url: url,
      data: data,
      headers: headers,
      success: http_success,
      dataType: 'json',
    });
  };

  var http_success = function (resp) {
    var filteredResults = resp.filter(function (log) {
      return (
        log.status == 'Acceptat' &&
        log.start_position_lat &&
        log.start_position_lat != '' &&
        log.start_position_lng &&
        log.start_position_lng != '' &&
        (log.location == 'Reci' ||
          log.location == 'Radauti' ||
          log.location == 'Sebes')
      );
    });

    root.logEntries = filteredResults;
    root.logEntries.forEach(function (e) {
      if (!e.start_position_lat) {
        // console.log(e);
      }
    });

    root.startDate = moment(filteredResults[0].start_at).valueOf();

    generate_timedisplay();
    generate_timings();
    $('#widget_playBtn').circleProgress('value', 0);
  };

  var generate_timings = function () {
    for (var i = 0; i < root.logEntries.length; i++) {
      var startDate = moment(root.logEntries[i].start_at).valueOf();

      var diff = ((startDate - root.startDate) / 1 / vars.relativeTime).toFixed(
        0
      );
      root.timings.push(diff);
    }

    root.alltransports = root.timings.length;

    setTimeout(function () {
      updateGametime();
    }, 50);
  };

  var generate_timedisplay = function () {
    var startDate = moment(root.logEntries[0].start_at);
    var endDate = moment(root.logEntries[root.logEntries.length - 1].start_at);
    $('#display_date').html(
      startDate.format('DD/MM/YYYY') + '<br>' + endDate.format('DD/MM/YYYY')
    );
  };

  var update_timedisplay = function () {
    var nDate = moment(root.logEntries[0].start_at);
    $('#display_date').html(
      nDate.format('DD/MM/YYYY') + '<br>' + nDate.format('HH:mm')
    );
  };

  var updateGametime = function () {
    if (root.timings.length > 0) {
      if (root.isRunning) {
        root.game_time = root.game_time + 100;
        if (root.timings[0] < root.game_time && root.timings.length > 0) {
          root.timings.shift();
          generate_marker();
          update_progress();
          // console.log(root.timings.length);
        } else {
          // console.log('adll done');
        }
      }

      setTimeout(function () {
        updateGametime();
      }, 50);
    } else {
      root.toggle_play();
      root.game_time = 0;

      read_data();
      // console.log(root.logEntries);
      // generate_timedisplay();
      // generate_timings();
    }
  };

  var update_progress = function () {
    var current = root.alltransports - root.timings.length;
    var percentage = (100 * current / root.alltransports / 100).toFixed(3);
    $('#widget_playBtn').circleProgress('value', percentage);
  };

  var generate_marker = function () {
    var currentLog = root.logEntries[0];
    var marker = new SlidingMarker({
      position: {
        lat: currentLog.start_position_lat,
        lng: currentLog.start_position_lng,
      },
      map: root.map,
      icon: root.transportIcon,
      optimized: true,
    });
    update_timedisplay();

    var markerId = currentLog.id;
    marker.setDuration(2500);
    if (currentLog.location == 'Radauti') {
      var destination = root.factoriesPositions.radauti.position;
    } else if (currentLog.location == 'Sebes') {
      var destination = root.factoriesPositions.sebes.position;
    } else if (currentLog.location == 'Reci') {
      var destination = root.factoriesPositions.reci.position;
    } else {
      var destination = root.factoriesPositions.reci.position;
    }

    marker.setPosition(destination);

    var line = new google.maps.Polyline({
      path: [
        {
          lat: currentLog.start_position_lat,
          lng: currentLog.start_position_lng,
        },
        destination,
      ],
      geodesic: true,
      strokeColor: '#3d8063',
      strokeOpacity: 0.2,
      strokeWeight: 2,
      map: root.map,
    });

    root.lines[markerId] = line;
    root.markers[markerId] = marker;
    setTimeout(function () {
      root.markers[markerId].setMap(null);
      root.markers[markerId] = null;
      root.lines[markerId].setMap(null);
      root.lines[markerId] = null;
    }, 2800);

    root.logEntries.shift();
  };

  root.toggle_play = function () {
    root.isRunning = !root.isRunning;
    if (root.isRunning) {
      $('#startBtnImg').attr('src', 'images/pauseBtn.png');
    } else {
      $('#startBtnImg').attr('src', 'images/startBtn.png');
    }

    update_progress();
  };

  root.reset_play = function () {
    root.isRunning = false;
    root.game_time = 0;
  };

  this.construct(options);
};

var timflow_widget = new Timflow_widget({ mapContainer: 'widget_parent' });
